<template>
	<div>
		<a-spin :spinning="loading">
			<a-row>
				<a-col :span="24" style="margin-bottom: 14px;">
					<a-row>
						<div class="ui-task">
							<span>当前盘点任务：</span>
							{{currTaskName}}
						</div>
						<!-- <div class="ui-form__item ui-form__uniItem" style="line-height: 34px;" v-if="$store.state.count.unitList.lenght <= 1">
							<span>所属单位：</span>
							{{$store.state.count.unitList[0].name}}
						</div> -->
						<!-- <a-form-item label="所属单位" class="ui-form__item ui-form__uniItem" v-else>
							<a-select  allow-clear  :bordered="false" style="width: 190px;" @select="selChange">
								<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
							</a-select>
						</a-form-item> -->
					</a-row>
				</a-col>
				<a-col :span="16">
					<a-button @click="onSearchBtn">
						<template #icon><Icon icon="SearchOutlined"/></template>
						查询</a-button>
					<a-button>
						<template #icon><Icon icon="UploadOutlined"/></template>
						导出</a-button>
					<a-button>
						<template #icon><Icon icon="LogoutOutlined"/></template>
						导出全部</a-button>
					<a-button @click="onSearch">
						<template #icon><Icon icon="ReloadOutlined"/></template>
						刷新</a-button>
					<a-button @click="onSort">
						<template #icon><Icon icon="OrderedListOutlined"/></template>
						排序</a-button>
				</a-col>
				<a-col :span="8" style="text-align: right;">
				</a-col>
			</a-row>
			
			<div v-show="isSearch">
				<a-form class="ui-form" ref="formRef" :model="formModal" layout="inline">
					<!-- <a-form-item label="所属部门" class="ui-form__item">
						<a-select v-model:value="formModal.depId"  allow-clear style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.departmentList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item> -->
					<a-form-item label="所属单位" class="ui-form__item ui-form__uniItem">
						<a-select  allow-clear v-model:value="formModal.unitId"  style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item class="ui-form__item">
						<div style="text-align: center;">
							<a-button @click="onReset">重置</a-button>
							<a-button type="primary" @click="onSearch">搜索</a-button>
						</div>
					</a-form-item>
				</a-form>
			</div>
			
			<div v-show="isSort" class="ui-form" style="padding-bottom: 20px;">
				<a-row style="align-items: center;">
					<a-col :span="18">
						<a-checkbox-group v-model:value="sortList">
							<a-checkbox :value="'unitName'">单位名称</a-checkbox>
							<a-checkbox :value="'state'">状态</a-checkbox>
						</a-checkbox-group>
					</a-col>
					<a-col :span="6" style='text-align: right;'>
						<a-button type="primary" @click="onSortClick('asc')">升序</a-button>
						<a-button type="primary" @click="onSortClick('desc')">降序</a-button>
						<a-button type="primary" @click="onResetSortClick()">重置</a-button>
					</a-col>
				</a-row>
			</div>
			
			<div style="margin-top: 20px;">
				<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun" rowSelectionType="checkbox" :searchData="searchData" rowKey="id" @rowSelected="rowSelected" :scroll="{ x: 1500 }" bordered draggable>
					<template #bodyCell="{ column, record, index, pagination }">
						<template v-if="column.key === 'action'">
							<a-button type="link" size="small" :disabled="record.status < 3" @click="onResult(record)">盘点结果</a-button>
						</template>
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'status'">
							<div v-if="record.status === 1">未开始</div>
							<div v-if="record.status === 2">进行中</div>
							<div v-if="record.status === 3">待确认</div>
							<div v-if="record.status === 4">已完成</div>
						</template>
					</template>
				</c-Table>
				
				<!-- <a-table rowKey="id" class="table"
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" bordered 
					size="small"
					:scroll="{ x: 1500 }"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'action'">
							<a-button type="link" size="small" @click="onResult(record)">盘点结果</a-button>
						</template>
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'status'">
							<div v-if="record.status === 1">未开始</div>
							<div v-if="record.status === 2">进行中</div>
							<div v-if="record.status === 3">待确认</div>
							<div v-if="record.status === 4">已完成</div>
						</template>
					</template>
				</a-table> -->
			</div>
		</a-spin>
		<resultModal ref="resultModalRef" @onReset="onReset"></resultModal>
	</div>
</template>

<script>
	import { getUnitTaskSearchList } from '@/service/modules/inventory.js';
	import { Icon } from '@/components/icon/icon.js';
	import resultModal from '@/views/inventoryManage/components/resultModal.vue';
	import cTable from '@/components/cTable/index.vue';
	import exportModal from '@/components/exportModal/exportModal.vue';
	export default {
		components: { Icon, resultModal, cTable, exportModal },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				isExpand: false,
				searchData: {},
				sortList: [],
				formModal: {
					taskId: undefined
				},
				tempTaskId: null,
				currTaskName: '',
				// tempUniId: null
				list: [],
				getDataFun: getUnitTaskSearchList,
				type: 'inventoryDispatchStrategy', // 导出类型
				role: 1, // 导出角色
				condition: '3', // 导出条件
				selIdsData: {},
				columns: [{
					title: '行号',
					align: 'center',
					key: 'index'
				}, {
					title: '单位名称',
					align: 'center',
					dataIndex: "unitName"
				}, { 
					title: '应盘资产（件）',
					align: 'center',
					dataIndex: "num"
				}, {
					title: '已盘资产（件）',
					align: 'center',
					dataIndex: "alreadyNum"
				}, {
					title: '盘亏资产（件）',
					align: 'center',
					dataIndex: "lossNum"
				}, {
					title: '盘盈资产（件）',
					align: 'center',
					dataIndex: "profitNum"
				}, {
					title: '状态',
					align: 'center',
					key: 'status',
					dataIndex: "status"
				},  {
					title: '操作',
					align: 'center',
					key: 'action',
					fixed: 'right',
					width: 120
				}]
			}
		},
		created() {
			this.formModal.taskId = this.$route.query.id
			this.currTaskName = this.$route.query.taskName
			this.onSearch()
		},
		methods: {
			// async getData() {
			// 	this.loading = true;
			// 	try {
			// 		let ret = await getUnitTaskSearchList({
			// 			page: this.pagination.current,
			// 			pageSize: this.pagination.pageSize,
			// 			taskId: this.tempTaskId,
			// 			...this.searchData
			// 		});
			// 		this.loading = false;
			// 		if (ret.code === 200) {
			// 			console.log("inveProg",ret)
			// 			this.list = ret.data.list;
			// 			this.currTaskName = ret.data.list[0].taskName
			// 			this.pagination.total = ret.data.totalCount;
			// 		}
			// 	} catch(e) {
			// 		this.loading = false;
			// 	}
			// },
			onReset() {
				this.$refs.formRef.resetFields();
				this.formModal = {}
				this.formModal.taskId = this.$route.query.id
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));	
				// this.pagination.current = 1;
				// this.getData();
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			rowSelected(item) {
				this.selIdsData.ids = item.keys.join(',')
			},
			onSort() {
				this.isSort = !this.isSort;
			},
			onResult(item) {
				this.$refs.resultModalRef.open(item);
			},
			onSortClick(type){
				let list = [];
				this.sortList.forEach(element => {
					list.push({key:element,sort:type});
				});
				this.formModal.sort = list;
				console.log(this.formModal);
				this.onSearch();
			},
			onResetSortClick(){
				this.sortList = [];
				this.formModal.sort = undefined;
				this.onSearch();
			}
			// selChange(key) { // 所属单位下拉切换
			// 	console.log("切换单位id",key)
			// 	this.formModal = {}
			// 	this.formModal.unitId = key
			// 	this.onSearch()
			// }
		},
		watch: {
			tempTaskId(newVal,oldVal) {
				this.tempTaskId = newVal
				// console.log("newVal",newVal)
				this.onSearch()
			}
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
	.ui-task {
		font-size: 16px;
		font-weight: 500;
		line-height: 34px;
	}
	.ui-task span {
		color: #666666;
	}
	.ui-form__uniItem {
		margin-left: 20px;
	}
	.ui-exportBrn {
		margin-left: 16px;
	}
</style>